@import-normalize;
@import "./styles/vars.scss";

* {
  cursor: none;

  &:hover {
    cursor: none;
  }
}

:root {
  font-size: 62.5%;
}

#root {
  height: 100%;
}

body,
html {
  margin: 0px;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-color;
  color: $secondary-color;
  letter-spacing: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #222;
  text-decoration: none;
}

p {
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  // font-family: "Darker Grotesque", sans-serif;
}

img {
  display: block;
  max-width: 100%;
}
