@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.spinnerRoot {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;

  transition: opacity 2s ease-in-out;
}

.spinner {
  @include center($vertical: true, $horizontal: true);
  @include headline();

  div {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
    height: 1rem;
  }
}
